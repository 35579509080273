import React, {Component} from "react";
import styled from 'styled-components';
import Title from '../../components/Title/Title'
import IconImg from "../../images/icon.svg"
import Button from "../../components/Button/Button";
import UniversalLink from "../../components/UniversalLink"

const ListTitle = styled.div`
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  max-width: 440px;
  border-top: 1px solid ${({theme}) => theme.colors.primary};
  border-bottom: 1px solid ${({theme}) => theme.colors.primary};
`;
const ListItem = styled.div`
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 45px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 26px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
  ${({theme}) => theme.media.mobile} {
     padding-top: 20px;
     padding-bottom: 20px;
  }
  :hover {
      color: ${({theme}) => theme.colors.primary};
   }
  &.active {
      color: ${({theme}) => theme.colors.primary};
      :before {
        content: '';
        width: 15px;
        height: 15px;
        background: url(${IconImg});
        background-repeat: no-repeat;
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
         ${({theme}) => theme.media.mobile} {
            display: none;
         }
      }
  }
  
    ${({theme}) => theme.media.mobile} {
      padding-left: 0px;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
    }

`;
const Text = styled.div`
  position: relative;
 
  h1,h2,h3,h4,h5,h6 {
      font-family: ${({theme}) => theme.fontFamily.publicSans};
      font-size: 24px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 34px;
      margin-bottom: 40px;
      text-align: left;
      font-variation-settings: 'wdth' 700,'wght' 400;
      ${({theme}) => theme.media.mobile} {
          text-align: center;
      }
      
  }
  p {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 30px;
  }
`;

export default class Tab extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      active: 0,
      animate: true,
      text: props.fields[0].text,
      list: props.fields
    }
  }



  handleClick(index) {
    const clicked = index
    this.setState({animate: false, active: clicked})
    setTimeout(() => {
      this.setState({
        animate: true,
        text: this.state.list[index].text,
      })
    }, 1000);

  }


  render() {

    const title = this.props.title;

    return (
      <div id={this.props.id} className="container-fluid mb-4 mb-md-5 pb-0 pb-md-5">
        <div className="row justify-content-center text-center px-5 pt-0 mb-0 mb-md-5 pb-5 mt-5">
          <Title>{title}</Title>
        </div>
        <div className="row align-items-center px-3 px-md-5 mt-0 mt-md-5 pt-0 pt-md-0">
          <div className="col-12 col-md-5 pb-5 pb-md-0 marginLeftTab" data-sal="slide-up" data-sal-delay="300" data-sal-duration="1000" data-sal-easing="ease">
            <ListTitle data-sal="slide-up" data-sal-delay="100" data-sal-duration="600" data-sal-easing="ease" className={`${this.state.animate ? 'sal-animate' : ''}`}>

              {this.state.list && this.state.list.map((item, index) => {
                  return <ListItem onClick={() => this.handleClick(index)} key={index}
                                   className={`d-block ${this.state.active === index ? 'active' : ''}`}>
                    {item.title}
                  </ListItem>;
                }
              )}
            </ListTitle>
          </div>
          <div className="col-12 col-md-7" data-sal="slide-up" data-sal-delay="300" data-sal-duration="1000" data-sal-easing="ease">
            <Text data-sal="slide-up" data-sal-delay="100" data-sal-duration="600" data-sal-easing="ease"
                  className={`mb-5 text-animate ${this.state.animate ? 'sal-animate' : ''}`}
                  dangerouslySetInnerHTML={{__html: this.state.text}}/>
            <div className={`text-center text-md-left position-relative ${this.state.animate ? 'sal-animate' : ''}`}
                 data-sal="slide-up"
                 data-sal-delay="100"
                 data-sal-duration="600"
                 data-sal-easing="ease">
              <Button as={UniversalLink} to={this.props.buttonLink} mobileWidth>{this.props.buttonText}</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

