import styled from 'styled-components'

const Button = styled.button`
  box-sizing: border-box;
  padding: 18px 65px;
  border: 1px solid ${({theme}) => theme.colors.primary};
  background-color: ${({theme}) => theme.colors.primary};
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.white};
  font-family: ${({theme}) => theme.fontFamily.publicSans};
  
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  
  :hover {
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
    &:before {
      transform: scaleX(1);
    }
  }
  :active {
    color: ${({theme}) => theme.colors.primary};
    text-decoration: none;
    &:before {
      transform: scaleX(1);
    }
  }
  :before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({theme}) => theme.colors.white};
    transform: scaleX(0);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
  }
  ${({theme}) => theme.media.mobile} {
    padding: 18px 16px;
    min-width: ${({mobileWidth}) => mobileWidth ? '100%' : '200px'};
    
  }
  
}
`;

export default Button;